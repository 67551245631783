import * as React from "react"
import Layout from "../components/layouts/layout"
import { SeoC } from "../components/utils/seo"

const SSRPage = ({ serverData }) => (
  <Layout>
  <div className="dogs">
    
    <div className="dogs--bx">
      <p>{serverData.data[0].attributes.body}</p>
    </div>
    
  </div>
  </Layout>
)

export default SSRPage

export const Head = () => (
  <SeoC title="Dog Fact!" />
)

export async function getServerData() {
  try {
    const res = await fetch(`https://dogapi.dog/api/v2/facts`,{
      headers: {
        'Accept': 'application/json',
      }
    })

    if (!res.ok) {
      throw new Error(`Response failed`)
    }

    return {
      props: await res.json(),
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {}
    }
  }
}