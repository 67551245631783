import React from 'react'


 const Footer = () => {

    return (
        <footer className={`footer`}>
               
                
        </footer>
    )
}

export default Footer
