import React from 'react'

 const Header = () => {

    return (
        <header className="header">
        </header>
    )
}

export default Header
