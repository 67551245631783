
import React, {useEffect} from "react";
// import { useStaticQuery, graphql } from "gatsby";
import useScrollPosition from "../utils/scrollPosition";
import Header from './header';
import Footer from "./footer";
import "../../styles/main.scss";


const Layout = ({ children, location }) => {

  const scrollPosition = useScrollPosition();

  const floatClass = scrollPosition > 50 ? 'float' : ''


  useEffect(() => {
    

  }, [])


  return (
    <div className={`site ` + floatClass}>
        <Header />
        <main className="page">{children}</main>
        <Footer />
    </div>
  );
};


export default Layout;
